<template>
  <div class="suppliers">
    <div class="page_header">
      <h1>Поставщики</h1>
      <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Добавить</el-button>
    </div>
    <div class="page_content" v-loading="loading">
      <div class="list" v-if="suppliers">
        <table class="table">
          <thead>
          <tr>
            <th>#</th>
            <th>Поставщик</th>
            <th>Комментарий</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(supplier, idx) in suppliers" :key="supplier.id">
            <td>{{ ++idx }}</td>
            <td>{{ supplier.title }}</td>
            <td>{{ supplier.comment }}</td>
            <td>
              <el-button
                  type="primary"
                  size="mini"
                  @click="openDrawer(supplier)"
                  icon="el-icon-edit">
                Изменить
              </el-button>
              <el-popconfirm
                  @confirm="remove(supplier.id)"
                  confirm-button-text='Да'
                  cancel-button-text='Нет'
                  title="Вы действительно хотите удалить?">
                <el-button
                    slot="reference"
                    icon="el-icon-delete"
                    size="mini" type="danger"
                    style="margin-left: 5px;"></el-button>
              </el-popconfirm>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p>Нет данных для отображения</p>
      </div>
    </div>
    <el-drawer
        :visible.sync="drawerActive"
        direction="rtl"
        size="30%"
        custom-class="drawer_box"
        :before-close="closeDrawer"
        :destroy-on-close="true"
        :show-close="false"
        :wrapperClosable="false"
        :withHeader="false"
        :append-to-body="true"
        class="settings_drawer"
    >
      <div class="drawer_header">
        <h3 class="title">Поставщик</h3>
        <button class="close_btn" @click="closeDrawer">
          <i class="el-icon-close"></i>
        </button>
      </div>

      <div class="drawer_content" v-if="supplierForm" v-loading="submitting">
        <el-form :model="supplierForm" :rules="supplierFormRules" ref="supplierForm" class="form"
                 @submit.native.prevent="supplierFormAction('supplierForm')">
          <el-form-item label="Наименование поставщика" prop="title">
            <el-input v-model="supplierForm.title" size="mini"></el-input>
          </el-form-item>

          <el-form-item label="Комментарий" prop="comment">
            <el-input
                type="textarea"
                :autosize="{ minRows: 2 }"
                placeholder="Комментарий"
                v-model="supplierForm.comment"
                size="mini"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
                type="primary"
                size="mini"
                @click="supplierFormAction('supplierForm')">Сохранить
            </el-button>
            <el-button size="mini" @click="closeDrawer">Отменить</el-button>
          </el-form-item>

        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as supplierActions} from "@/store/modules/suppliers";

export default {
  name: "Suppliers",
  data() {
    return {
      drawerActive: false,
      supplierForm: null,
      supplierFormRules: {
        title: [
          {required: true, message: 'Введите наименование поставщика', trigger: 'blur'}
        ]
      }
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.suppliers.loading,
      submitting: (state) => state.suppliers.submitting,
      suppliers: (state) => state.suppliers.list,
      error: (state) => state.suppliers.error,
    })
  },
  mounted() {

  },
  methods: {
    loadSuppliers() {
      this.$store.dispatch(supplierActions.get);
    },
    remove(id){
      this.$store.dispatch(supplierActions.remove, id).then(() => {
        this.loadSuppliers()
      })
    },
    openDrawer(data) {
      this.supplierForm = _.cloneDeep(data);
      this.drawerActive = true;
    },
    closeDrawer() {
      this.drawerActive = false;
      this.supplierForm = null;
    },
    create() {
      this.supplierForm = {
        title: "",
        comment: "",
      };
      this.drawerActive = true;
    },
    supplierFormAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch(supplierActions.save, this.supplierForm).then(() => {
            this.loadSuppliers()
            this.drawerActive = false
            this.supplierForm = null
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  watch: {},
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>
